<template>
    <div class="registered">
        <!-- <headerlayout/> -->
        <div class="main">
            <loader v-if="loading"/>
            <div v-else>
                <div class="icon">
                    <check :size="'70px'"/>
                </div>
                <div class="title">Registration Submitted</div>
                <div class="text">
                    Your registration has been submitted, 
                    you will be notified via email once your registration is approved
                </div>

                <div class="box">
                    <div class="box_title">Your Details</div>
                    <div class="item">
                        <div class="label">Firstname:</div>
                        <div class="value">{{$root.$data.firstname}}</div>
                    </div>
                    <div class="item">
                        <div class="label">Lastname:</div>
                        <div class="value">{{$root.$data.lastname}}</div>
                    </div>
                    <div class="item">
                        <div class="label">Phone Number:</div>
                        <div class="value">{{$root.$data.phone}}</div>
                    </div>
                    <div class="item">
                        <div class="label">Email:</div>
                        <div class="value">{{$root.$data.email}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <img src="@/assets/logo.png"/>
            Bitz Music
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('bitz')

// import headerlayout from '@/components/head.vue'
import check from '@/icons/check.vue'

import loader from '@/components/util/loader.vue'


export default {
    components: {
        // headerlayout,
        check,
        loader
    },
    data() {
        return {
            loading: true
        }
    },
    mounted() {
        this.$http.post('/user/check_verification')
        .then(data => {
            let r = data.data
            if (r.success) {
                if (r.verified) {
                    db.collection('auth').doc({'id': 1}).update({
                        'verified': true
                    }).then(() => {
                        this.$root.$data.verified = true
                        this.$router.push('/home')
                    })
                } else {
                    this.loading = false
                }
            }
        })
    }
}
</script>

<style scoped>
    
    .registered {
        height: 100%;
        overflow: auto;
        background-color: white;

        display: grid;
        grid-template-rows: auto max-content;
    }


    .main {
        padding: 20px;
        
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .icon {
        fill: var(--main);
        text-align: center;
        /* margin-bottom: 10px; */
    }
    .title {
        font-weight: 800;
        text-align: center;
        margin-bottom: 10px;
    }
    .text {
        font-size: 12px;
        line-height: 18px;
        color: gray;
        text-align: center;
    }


    .box {
        margin-top: 20px;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid #ddd;
    }
    .box_title {
        /* font-size: 14px; */
        font-weight: 800;
        /* background-color: #edeef0; */
        padding: 15px;
        border-bottom: 1px solid #ddd;
    }
    
    .item {
        /* background-color: #edeef0; */
        padding: 10px 15px;
        margin-top: 2px;
        /* border-bottom: 1px solid #ddd; */
    }
    .label {
        font-size: 12px;
        color: gray;
        margin-bottom: 2px;
    }
    .value {
        /* font-size: 14px; */
        font-weight: 600;
    }


    .bottom {
        padding: 15px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: var(--main);
        color: white;
        background-color: #010051;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .bottom img {
        width: 20px;
    }
</style>