<template>
    <div class="admin">
        <div class="box">
            <div class="title">Admin Login</div>
            <div class="input">
                <div class="label">Username:</div>
                <input type="text" placeholder="Enter username" v-model="username">
            </div>
            <div class="input">
                <div class="label">Password:</div>
                <input type="password" placeholder="Enter password" v-model="password">
            </div>
            <btn_loader :height="'49px'" v-if="loading"/>
            <div class="btn" v-else @click="login()">Login</div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('bitz')

import btn_loader from '../../components/util/btn_loader.vue'

export default {
    components: {
        btn_loader
    },
    data() {
        return {
            username: '',
            password: '',
            loading: false
        }
    },
    mounted() {
        db.collection('auth').doc({ id: 2 }).get().then( auth => {
            if ( auth == undefined ) {
                console.log('ll');
            } else {
                let user = auth.key.split('-')[0]
                if (user == 'admin') {
                    this.$http.defaults.headers.common['Auth'] = auth.key
                    setTimeout(() => {
                        this.$router.push('/admin')
                    }, 1000)
                } else {
                    this.$router.push('/')
                }
            }
        })
    },
    methods: {
        login() {
            if (this.username == '' || this.password == '') {
                this.empty(); return
            }
            this.loading = true

            let form = new FormData()
            form.append('username', this.username)
            form.append('password', this.password)

            this.$http.post('/admin/login', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    db.collection('auth').add({
                        'id': 2,
                        'key': r.session,
                        'username': this.username,
                    }).then( () => {
                        this.$root.$data.login = true
                        this.$root.$data.username = r.username
                        
                        this.$http.defaults.headers.common['Auth'] = r.session

                        setTimeout(() => {
                            this.$router.push('/admin')
                        }, 1000);
                    })
                } else {
                    if (r.username) {
                        this.username_err()
                    }
                    if (r.password) {
                        this.password_err()
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter your username and password'
        },
        username_err: {
            type: 'warn',
            title: 'Username',
            message: 'Username not found'
        },
        password_err: {
            type: 'warn',
            title: 'Password',
            message: 'Incorrect password'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'You are now logged in'
        }
    }
}
</script>

<style scoped>
    .admin {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100vh;

    }
    .box {
        background-color: white;
        width: 300px;
        padding: 20px;
        border-radius: 5px;
    }
    .title {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .input {
        margin-bottom: 20px;
    }
    .label {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 3px;
    }
    input {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        background-color: var(--main);
        color: white;
        padding: 15px;
        text-align: center;
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        border-radius: 5px;
    }
</style>