<template>
    <div class="track" @click="open_track()">
        <div class="image">
            <img :src="imgurl + track.image"/>
        </div>
        <div>
            <div class="title">{{track.title}}</div>
            <div class="small">
                <div>
                    <span v-if="is_played">{{ play_time }} |</span>
                    {{duration}}
                </div>
                <div>·</div>
                <div>{{track.comments}} Comments</div>
            </div>
        </div>
        <div class="play">
            <play :size="'35px'" :track="track"/>
        </div>
    </div>
</template>

<script>
import play from '../util/play.vue'

export default {
    components: {
        play
    },
    props: ['track', 'tracks'],
    computed: {
        imgurl() {
            return this.$root.$data.uploadurl + 'images/'
        },
        is_played() {
            return this.$root.$data.play_track.track_id == this.track.track_id
        },
        root_play_time() {
            return this.$root.$data.play_time
        }
    },
    watch: {
        root_play_time(val) {
            
            const m = Math.floor(val % 3600 / 60).toString().padStart(2,'0'),
                s = Math.floor(val % 60).toString().padStart(2,'0');
            
            this.play_time = m + ':' + s;
        }
    },
    data() {
        return {
            duration: '',
            play_time: ''
        }
    },
    mounted() {
        
        const m = Math.floor(this.track.duration % 3600 / 60).toString().padStart(2,'0'),
            s = Math.floor(this.track.duration % 60).toString().padStart(2,'0');
        
        this.duration = m + ':' + s;
    },
    methods: {
        open_track() {
            localStorage.setItem('track', JSON.stringify(this.track))
            localStorage.setItem('tracks', JSON.stringify(this.tracks))

            this.$router.push('/track/' + this.track.track_id)
        }
    }
}
</script>

<style scoped>
    .track {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-top: 10px;
        padding: 15px 20px;
        background-color: #edeef0;
        border-radius: 5px;

        transition: .5s;
    }
    .track:active {
        background-color: white;
        /* transform: scale(0.95); */
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 45px;
        height: 45px;
        overflow: hidden;
        border-radius: 5px;
    }
    .image img {
        width: 50px;
    }

    .title {
        font-weight: 800;
    }
    .small {
        display: flex;
        color: gray;
        font-size: 12px;
        margin-top: 3px;
        gap: 5px;
    }

    .play {
        margin-left: auto;
        fill: var(--main);
        cursor: pointer;
    }
</style>