<template>
    <div class="signup">
        <div class="image">
            <img src="../assets/music.jpg"/>
        </div>
        <div class="main">
            <div class="title">SIGN UP</div>
            <div class="text">Fill in your details below to signup</div>
            <div class="input">
                <div class="label">Firstname</div>
                <input type="text" placeholder="Enter your firstname" v-model="firstname">
            </div>
            <div class="input">
                <div class="label">Lastname</div>
                <input type="text" placeholder="Enter your lastname" v-model="lastname">
            </div>
            <div class="input">
                <div class="label">Phone Number</div>
                <input type="text" placeholder="Enter your phone number" v-model="phone">
            </div>
            <div class="input">
                <div class="label">Email</div>
                <input type="text" placeholder="Enter your email" v-model="email">
            </div>
            <div class="input">
                <div class="label">Password</div>
                <input type="password" placeholder="Enter your password" v-model="password">
            </div>

            <btn_loader :height="'49px'" v-if="loading"/>
            <div class="btn" @click="signup()" v-else>Register</div>
            
            <router-link to="/signin">
                <div class="bottom">
                    Have an account? <span>SIGN IN</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>

import Localbase from 'localbase'
let db = new Localbase('bitz')

import btn_loader from '@/components/util/btn_loader.vue'

export default {
    
    components: {
        btn_loader
    },
    data() {
        return {
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            password: '',
            loading: false
        }
    },
    mounted() {
        if (this.$root.$data.is_login) {
            this.$router.push('/user')
        }
    },
    methods: {
        signup() {
            if (this.firstname == '' || this.lastname == '' || this.phone == '' || this.email == '' ||
                this.password == '') {
                    this.empty(); return
                }
            this.loading = true

            let form = new FormData()
            form.append('firstname', this.firstname)
            form.append('lastname', this.lastname)
            form.append('phone', this.phone)
            form.append('email', this.email)
            form.append('password', this.password)

            this.$http.post('/user/signup', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    db.collection('auth').add({
                        'id': 1,
                        'key': r.session,
                        'email': this.email,
                        'firstname': this.firstname,
                        'lastname': this.lastname,
                        'phone': this.phone,
                        'user_id': r.user_id,
                        'verified': false
                    }).then( () => {
                        this.$root.$data.is_login = true
                        this.$root.$data.firstname = this.firstname
                        this.$root.$data.lastname = this.lastname
                        this.$root.$data.email = this.email
                        this.$root.$data.phone = this.phone
                        this.$root.$data.user_id = r.user_id
                        this.$http.defaults.headers.common['Auth'] = r.session
                        localStorage.setItem('email', this.email)
                        setTimeout(() => {
                            this.$router.push('/user')
                        }, 1000);
                    })

                } else {
                    if (r.email) {
                        this.email_err()
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled'
        },
        email_err: {
            type: 'warn',
            title: 'Email',
            message: 'This email has already been registered'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Your account has been created'
        },
    }
}
</script>


<style scoped>
    .signup {
        height: 100vh;
        overflow: auto;
        background-color: white;

        display: grid;
        grid-template-rows: auto max-content;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        min-height: 200px;
    }
    img {
        height: 100%;
        transform: scale(2);
    }


    .main {
        padding: 20px;
        box-sizing: border-box;
        height: 100%;
        overflow: auto;
    }
    .title {
        font-weight: 800;
        margin-bottom: 2px;
        font-size: 18px;
    }
    .text {
        font-size: 14px;
        color: gray;
        margin-bottom: 30px;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -18px;
        left: 0px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 2px;
        display: none;
    }
    input {
        padding: 17px;
        border: none;
        background-color: #edeef0;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        background-color: var(--main);
        color: white;
        padding: 15px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        font-weight: 500;
    }

    
    .bottom {
        font-size: 14px;
        font-weight: 500;
        margin-top: 20px;
        font-size: 14px;
        text-align: center;

        background-color: var(--trans);
        padding: 15px;
        margin: 30px -20px;
        margin-bottom: -20px;
    }
    .bottom span {
        font-weight: 800;
        cursor: pointer;
        color: var(--main);
    }

</style>
