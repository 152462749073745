<template>
    <div class="ep">
        <div class="flex" @click="show_main = !show_main">
            <div class="title">
                {{ ep.title }}
            </div>
            <div class="tracks">{{ ep.tracks }} Tracks</div>
            <div class="icon" :class="[show_main ? 'rot' : '']">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
        </div>
        <div class="main" v-if="show_main">
            <div class="menu">
                <div class="item a">Tracks</div>
                <div class="item">Votes</div>
                <div class="item">Details</div>
            </div>

            <div class="section">
                <div class="section_head">
                    <div class="section_title">Tracks</div>
                    <div class="btn" @click="show_add = true">Add Track</div>
                </div>
                <loader class="l" v-if="loading"/>
                <trackvue v-for="track in tracks" :key="track.track_id" :track="track"/>
            </div>
        </div>

        <add_track v-if="show_add" @close="show_add = false" :ep="ep"/>
    </div>
</template>

<script>
import arrow from '@/icons/arrow.vue'

import loader from '@/components/util/loader.vue'
import trackvue from './track.vue'

import add_track from './add_track.vue'

export default {
    components: {
        arrow,
        loader, trackvue,
        add_track
    },
    props: ['ep'],
    data() {
        return {
            show_main: false,

            tracks: [],
            loading: true,

            show_add: false
        }
    },
    mounted() {
        let form = new FormData()
        form.append('ep_id', this.ep.ep_id)

        this.$http.post('/admin/tracks/get', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.tracks = r.tracks
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>
    .ep {
        border-radius: 5px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    .flex {
        padding: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;
    }
    .title {
        font-weight: 600;
    }
    .tracks {
        background-color: var(--trans);
        color: var(--main);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 600;
    }
    .icon {
        margin-left: auto;
        transition: .3s;
    }
    .rot {
        transform: rotate(90deg);
    }


    .main {
        border-top: 1px solid #ddd;
    }
    .menu {
        border-bottom: 1px solid #ddd;
        display: flex;
    }
    .item {
        padding: 15px 20px;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 2px solid transparent;
        cursor: pointer;
    }
    .a {
        color: var(--main);
        border-bottom-color: var(--main);
    }

    .section {
        padding: 20px;
    }
    .section_head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .section_title {
        font-weight: 600;
    }
    
    .btn {
        padding: 8px 20px;
        font-weight: 500;
        font-size: 14px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }

    .l {
        margin-top: 20px;
    }
</style>