<template>
    <div class="user">
        <headerlayout/>
        <div class="main">
            <router-view/>
        </div>
    </div>
</template>

<script>
import headerlayout from '@/components/head.vue'
export default {
    components: {
        headerlayout
    },
    mounted() {
        
        if (!this.$root.$data.is_login) {
            this.$router.push('/signin')
        } else {
            if (!this.$root.$data.verified) {
                this.$router.push('/registered').catch(()=>{})
            } 
            if (this.$route.name == 'user') {
                this.$router.push('/home').catch(()=>{})
            }
        }
    },
}
</script>

<style scoped>
    .user {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    .main {
        flex: auto;
        overflow: auto;
    }
</style>