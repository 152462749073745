<template>
    <div class="signin">
        <div class="image">
            <img src="../assets/music.jpg"/>
        </div>
        <div class="main">
            <div class="title">Sign in</div>
            <div class="text">Enter your email and password to login</div>
            <div class="input">
                <div class="label">Email</div>
                <input type="text" placeholder="Enter your email" v-model="email">
            </div>
            <div class="input">
                <div class="label">Password</div>
                <input type="password" placeholder="Enter your password" v-model="password">
            </div>

            <btn_loader :height="'49px'" v-if="loading"/>
            <div class="btn" v-else @click="login()">Sign In</div>

            <div class="forgot">Forgot Password?</div>

            <router-link to="/signup">
                <div class="signup">
                    Not registered? <span>Sign up</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('bitz')

import btn_loader from '@/components/util/btn_loader.vue'


export default {
    
    components: {
        btn_loader
    },
    data() {
        return {
            email: '',
            password: '',
            loading: false
        }
    },
    mounted() {
        if (this.$root.$data.is_login) {
            this.$router.push('/user')
        }
    },
    methods: {
        login() {
            if (this.email == '' || this.password == '') {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('email', this.email)
            form.append('password', this.password)

            this.$http.post('/user/login', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    db.collection('auth').add({
                        'id': 1,
                        'key': r.session,
                        'email': this.email,
                        'firstname': r.firstname,
                        'lastname': r.lastname,
                        'phone': r.phone,
                        'user_id': r.user_id,
                        'verified': r.verified
                    }).then( () => {
                        this.$root.$data.is_login = true
                        this.$root.$data.firstname = r.firstname
                        this.$root.$data.lastname = r.lastname
                        this.$root.$data.user_id = r.user_id
                        this.$root.$data.verified = r.verified
                        this.$http.defaults.headers.common['Auth'] = r.session
                        localStorage.setItem('email', this.email)
                        setTimeout(() => {
                            this.$router.push('/user')
                        }, 1000);
                    })

                } else {
                    if (r.email) {
                        this.email_err()
                    }
                    if (r.password) {
                        this.password_err()
                    }
                }
                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter your email and password'
        },
        email_err: {
            type: 'warn',
            title: 'Email',
            message: 'Incorrect email'
        },
        password_err: {
            type: 'warn',
            title: 'Password',
            message: 'Incorrect password'
        },
        success: {
            type: 'success',
            title: '',
            message: 'You are now logged in'
        },
    }
}
</script>


<style scoped>
    .signin {
        height: 100vh;
        background-color: white;

        display: grid;
        grid-template-rows: auto max-content;
    }
    .image {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

    }
    img {
        height: 100%;
        transform: scale(1.5);
    }


    .main {
        padding: 20px;
        box-sizing: border-box;
        height: 100%;
        overflow: auto;
    }
    .title {
        font-weight: 800;
        margin-bottom: 2px;
        font-size: 18px;
    }
    .text {
        font-size: 14px;
        color: gray;
        margin-bottom: 30px;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -18px;
        left: 0px;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 2px;
        display: none;
    }
    input {
        padding: 17px;
        border: none;
        background-color: #edeef0;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        background-color: var(--main);
        color: white;
        padding: 15px;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        font-weight: 500;
    }

    .forgot {
        margin-top: 20px;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        color: var(--main);
    }
    .signup {
        font-size: 14px;
        font-weight: 500;
        margin-top: 20px;
        font-size: 14px;
        text-align: center;

        background-color: var(--trans);
        padding: 15px;
        margin: 20px -20px;
        margin-bottom: -20px;
    }
    .signup span {
        font-weight: 800;
        cursor: pointer;
        color: var(--main);
    }

</style>
