<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="title">Add EP</div>

            <div class="upload" @click="upload()">
                <img :src="image" alt="" v-if="is_image">
                <div class="upload_text" v-else>Click to upload EP cover image</div>
            </div>
            <div class="input">
                <div class="label">Title</div>
                <input type="text" placeholder="Enter EP title" v-model="title">
            </div>

            <btn_loader :height="'39px'" v-if="loading"/>
            <div class="btn" @click="add()" v-else>Add</div>
        </div>

        <input type="file" accept="image/*" class="hidden" ref="image" @change="handleupload($event)"/>

    </div>
</template>

<script>
import * as imageConversion from 'image-conversion';

import btn_loader from '@/components/util/btn_loader.vue';

export default {
    components: {
        btn_loader
    },
    data() {
        return {
            title: '',
            is_image: '',
            image: '',
            image_file: '',
            loading: false
        }
    },
    methods: {
        upload() {
            this.$refs['image'].click()
        },
        handleupload(event) {
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                
                this.compress(picture)
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this.image = event.target.result

                    this.$refs['image'].value = ''

                }
            }
        },
        compress(file) {
            let name = file.name
            
            imageConversion.compressAccurately(file, 70).then(res=>{
                this.is_image = true
                
                this.image_file = new File([res], name)
            })
        },
        add() {
            if (this.title == '' || !this.is_image) {
                this.empty(); return
            }
            this.loading = true
            let form = new FormData()
            form.append('title', this.title)
            form.append('image', this.image_file)

            this.$http.post('/admin/ep/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()

                    setTimeout(() => {
                        this.$router.go()
                    }, 1000);
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Enter EP title and select image'
        },
        success: {
            type:'success',
            title: '',
            message: 'EP has been added'
        }
    }
}
</script>

<style scoped>
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;

        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        position: relative;
        background-color: white;
        border-radius: 5px;
        width: 300px;
        padding: 20px;
        max-height: 80vh;
        overflow: auto;
    }

    .title {
        font-weight: 800;
        margin-bottom: 20px;
    }

    .upload {
        background-color: #edeef0;
        aspect-ratio: 1/1;
        border-radius: 5px;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        cursor: pointer;
    }
    .upload_text {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    }
    .upload img {
        width: 100%;
    }

    .input {
        margin-bottom: 20px;
    }
    .label {
        font-size: 14px;
        font-weight: 500;
    }
    input {
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        padding: 10px;
        text-align: center;
        background-color: var(--main);
        color: white;
        font-weight: 600;
        font-size: 600;
        border-radius: 5px;
        cursor: pointer;
    }

    
    .hidden {
        display: none;
    }
</style>