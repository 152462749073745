<template>
    <div class="load" :style="{'height': height, 'width': width}">
        <div class="spin"></div>
    </div>
</template>

<script>
export default {
    props: ['height', 'width']
}
</script>

<style scoped>
    .load {
        background-color: var(--main);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
    }
    .spin {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 3px solid transparent;
        border-top-color: white;
        border-left-color: white;
        animation: rotate 1s infinite linear;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>