<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="title">Lyrics</div>
            <div class="text">
                {{ lyrics }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['lyrics']
}
</script>

<style scoped>
    
    .popup {
        position: fixed;
        z-index: 3;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        padding: 20px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;

        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        background-color: white;
        border-radius: 5px;
        position: relative;
        width: 300px;
        max-height: 70vh;
        overflow: auto;

        padding: 20px;
        border-left: 5px solid #46f2b6;
    }
    .title {
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 20px;
    }
    .text {
        margin-top: 5px;
        font-size: 14px;
        white-space: pre-line;
    }
</style>