<template>
    <div class="admin">
        <div class="head">
            <div class="title">Bitz Music Admin</div>
            <div class="btn" @click="logout()">Logout</div>
        </div>
        <div class="section" v-if="ready">
            <div class="menu">
                <router-link to="/admin">
                    <div class="item" :class="[route == 'dashboard' ? 'a' : '']">Dashboard</div>
                </router-link>
                <router-link to="/admin/users">
                    <div class="item" :class="[route == 'users' ? 'a' : '']">Users</div>
                </router-link>
                <router-link to="/admin/eps">
                    <div class="item" :class="[route == 'eps' ? 'a' : '']">EP's</div>
                </router-link>
            </div>
            <div class="main">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('bitz')


export default {

    data() {
        return {
            ready: false
        }
    },
    computed: {
        route() {
            return this.$route.name
        }
    },
    mounted() {
        db.collection('auth').doc({ id: 2 }).get().then( auth => {
            if ( auth == undefined ) {
                
                this.$router.push('/admin/login')
            } else {
                // let user = auth.key.split('-')[0]
                this.is_login = true
                this.$http.defaults.headers.common['Auth'] = auth.key
                setTimeout(() => {
                    this.ready = true
                }, 100)
            }
        })
    },
    methods: {
        logout() {
            db.collection('auth').doc({ id: 2 }).delete()
            this.$router.push('/admin/login')
        }
    }
}
</script>

<style scoped>
    .admin {
        height: 100vh;

        display: flex;
        flex-direction: column;
    }
    .head {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;
        border-bottom: 1px solid #ddd;
    }
    .title {
        font-weight: 800;
        font-size: 18px;
    }
    .btn {
        padding: 7px 20px;
        background-color: var(--main);
        color: white;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
        border-radius: 5px;
    }

    .section {
        flex: auto;
        background-color: white;

        display: flex;
    }
    .menu {
        width: 200px;
        border-right: 1px solid #ddd;
        padding: 20px;
    }
    .item {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 600;
    }
    .a {
        background-color: var(--main);
        color: white;
        border-color: white;
    }

    .main {
        flex: auto;
    }
</style>