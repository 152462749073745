import Vue from 'vue'
import App from './App.vue'
import router from './router'
import notify from './external/notify.js'

Vue.config.productionTip = false


import axios from 'axios'
import './registerServiceWorker'
Vue.prototype.$http = axios
// axios.defaults.baseURL = 'http://localhost/bitz/index.php/'
axios.defaults.baseURL = 'https://app.bitzmusic.org/api/index.php/'

new Vue({
    notify,
    router,
    data() {
        return {
            secret_key: 'fre@i59!23C0Dfn_389frKlIeqk9',

            pwa_install_prompt: null,
            displayMode: '',

            // uploadurl: 'http://localhost/bitz/uploads/',
            uploadurl: 'https://app.bitzmusic.org/api/uploads/',
            
            c: {
                main: 'rgb(31, 81, 255)',
                trans: 'rgba(31, 81, 255, 0.2)'
            },

            login: false,
            username: '',

            is_login: false,
            user_id: '',
            email: '',
            firstname: '',
            lastname: '',
            phone: '',
            verified: false,

            tracks: [],
            play_state: '',
            play_track: {
                track_id: ''
            },
            pause_track: {
                track_id: ''
            },
            play_time: 0,
            seek_track: {
                track_id: ''
            }
        }
    },
    render: h => h(App)
}).$mount('#app')
