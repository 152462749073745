import { render, staticRenderFns } from "./install.vue?vue&type=template&id=8fd41606&scoped=true"
import script from "./install.vue?vue&type=script&lang=js"
export * from "./install.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fd41606",
  null
  
)

export default component.exports