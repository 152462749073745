<template>
    <div class="play" :style="{'--size': size}">
        <div class="downloading" v-if="downloading">
            {{ download_percent }}%
        </div>
        <div v-else>
            <div v-if="is_played">
                <div class="icon" :class="[downloaded ? 'a' : '']" @click.stop="play()" 
                    v-if="play_state == ''">
                    <play :size="size"/>
                </div>
                <div class="icon" :class="[downloaded ? 'a' : '']" @click.stop="pause()" 
                    v-if="play_state == 'playing'">
                    <pause :size="size"/>
                </div>
            </div>
            <div class="icon" :class="[downloaded ? 'a' : '']" @click.stop="play()" v-else>
                <play :size="size"/>
            </div>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('bitz')

import CryptoJS from "crypto-js"

import play from '@/icons/play.vue'
import pause from '@/icons/pause.vue';

export default {
    components: {
        play, pause
    },
    props: ['size', 'track'],
    computed: {
        play_state() {
            return this.$root.$data.play_state
        },
        is_played() {
            return this.$root.$data.play_track.track_id == this.track.track_id
        }
    },
    data() {
        return {
            downloading: false,
            download_percent: 0,
            downloaded: false,

            file: [],
            playable_track: {}
        }
    },
    mounted() {
        this.playable_track = this.track

    },
    methods: {
        play() {
            if (this.downloaded) {
                this.$root.$data.play_track = { track_id: '' }
                setTimeout(() => {
                    this.$root.$data.play_track = this.playable_track
                }, 500);
            } else {
                this.download_file()
            }
        },
        pause() {
            this.$root.$data.pause_track = { track_id: '' }
            setTimeout(() => {
                this.$root.$data.pause_track = this.playable_track
            }, 500);
        },

        download_file() {
            this.downloading = true
            
            
            db.collection('tracks').doc({'id': this.track.track_id}).get()
            .then(data => {
                if (data) {
                    this.load_audio(data.blob, true)
                } else {
                
                    var request = new XMLHttpRequest();
                    let vm = this

                    request.addEventListener('readystatechange', function() {
                        if(request.readyState == 2 && request.status == 200) {
                            vm.downloading = true
                        }
                        else if(request.readyState == 3) {
                            // Download is under progress
                        }
                        else if(request.readyState == 4) {

                            db.collection('tracks').add({
                                'id': vm.track.track_id,
                                'blob': request.response
                            })

                            vm.load_audio(request.response, true)

                            // _OBJECT_URL = URL.createObjectURL(request.response);

                        }
                    });

                    request.addEventListener('progress', function(e) {
                        
                        var contentLength;
                        if (e.lengthComputable) {
                            contentLength = e.total;
                        } else {
                            contentLength = parseInt(e.target.getResponseHeader('x-decompressed-content-length'), 10);
                        }

                        console.log(contentLength);

                        vm.download_percent = parseInt((e.loaded / contentLength) * 100);
                        
                    });

                    request.responseType = 'blob';

                    let file = JSON.parse(this.track.files)[0]
                    request.open('get', this.$root.$data.uploadurl + 'audios/' + this.track.track_id + '/' + file); 

                    request.send()
                }
            })

        },
        load_audio(blob, play) {
            let vm = this

            var reader = new FileReader();
            reader.onload = function() {
                
                var bytes  = CryptoJS.AES.decrypt(reader.result, vm.$root.$data.secret_key);
                var originalText = bytes.toString(CryptoJS.enc.Utf8);
                
                // Convert Base64 string to Blob
                const byteCharacters = atob(originalText);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'audio/*' });

                // Create a URL from the Blob
                const blobUrl = URL.createObjectURL(blob);
                vm.playable_track['file'] = blobUrl

                
                vm.downloading = false
                vm.downloaded = true

                if (play) {
                    vm.play()
                }
            }
            reader.readAsText(blob);
        }
    }
}
</script>

<style scoped>
    .downloading {
        width: var(--size);
        height: var(--size);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        background-color: white;
        color: var(--main);
        border-radius: 50%;
        
        position: relative;
        z-index: 1;
    }
    .downloading:before {
        position: absolute;
        z-index: -1;
        top: -1px;
        left: -1px;

        content: "";
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        border: 2px solid var(--main);
        border-bottom-color: transparent;
        border-right-color: transparent;
        animation: rot .8s linear infinite;
    }
    @keyframes rot {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }


    .icon {
        position: relative;
        z-index: 1;
        width: var(--size);
        height: var(--size);
    }
    .a::before {
        position: absolute;
        z-index: -1;
        top: 0px;
        left: 0px;

        content: "";
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background-color: var(--main);
        opacity: .3;
        animation: pop .8s linear infinite;
    }
    @keyframes pop {
        0% {
            transform: scale(1);
        } 
        70% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
    .a:after {
        position: absolute;
        z-index: -1;
        top: 0px;
        left: 0px;

        content: "";
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        background-color: white;
        transform: scale(0.7);
    }
</style>