<template>
    <div class="track">
        <!-- <headerlayout/> -->
        <loading v-if="loading" class="l"/>
        <div class="main" v-else>
            <div class="image">
                <img :src="imgurl + track.image"/>
            </div>
            <div class="details">
                <div>
                    <div class="title">{{track.title}}</div>
                    <div class="artist">By {{track.artist}}</div>
                </div>
                <div class="icon" @click="show_lyrics = true">
                    <lyrics :size="'30px'"/>
                </div>
            </div>
            <div class="player" v-if="is_track">
                
                <vue-slider v-model="value" :min="0" :max="slidermax" :tooltip="'active'"
                    :tooltip-formatter="compute_tooltip" :contained="true"
                    @drag-end="slider_seek"
                    ref="slider"/>

                <!-- <div class="line">
                    <div class="circle"></div>
                </div> -->
                <div class="time">
                    <div v-if="is_played">{{ play_time }}</div>
                    <div v-else>00:00</div>
                    <div>{{duration}}</div>
                </div>
                <div class="controls">
                    <div class="icon">
                        <backward :size="'35px'"/>
                    </div>
                    <div class="icon">
                        <play :size="'70px'" :track="track"/>
                    </div>
                    <div class="icon">
                        <forward :size="'35px'"/>
                    </div>
                </div>
            </div>

            <div class="section">
                <div class="section_title">
                    Comments · {{track.comments}}
                    <div class="icon" @click="show_write = !show_write">
                        <write :size="'30px'" :style="{'height': '30px'}"/>
                    </div>
                </div>

                <div class="write" v-if="show_write">
                    <div class="write_title">Write Comment</div>
                    <textarea placeholder="Share your view" v-model="comment"></textarea>

                    <btn_loader :height="'42px'" v-if="write_loading"/>
                    <div class="btn" v-else @click="submit()">Submit</div>
                </div>

                <loading v-if="c_loading" class="l"/>

                <comment v-for="comment in comments" :key="comment.comment_id" :comment="comment"/>
            </div>
        </div>
        
        <div class="bottom">
            <img src="@/assets/logo.png"/>
            Bitz Music
        </div>

        <lyricspop :lyrics="track.lyrics" v-if="show_lyrics" @close="show_lyrics = false"/>
    </div>
</template>

<script>
// import headerlayout from '@/components/head.vue'

import loading from '@/components/util/loader.vue'

import lyrics from '@/icons/lyrics.vue'

import play from '@/components/util/play.vue'
import forward from '@/icons/forward.vue'
import backward from '@/icons/backward.vue'

import write from '@/icons/write.vue'
import btn_loader from '@/components/util/btn_loader.vue'

import comment from '@/components/home/comment.vue'

import lyricspop from '@/components/home/lyrics.vue'


import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
    components: {
        // headerlayout,
        loading,
        lyrics,
        play, 
        forward, backward,
        write,
        btn_loader,
        comment,
        lyricspop,
        VueSlider
    },
    data() {
        return {
            loading: false,
            track: {},
            is_track: false,
            tracks: [],
            value: '',
            slidermax: '',
            duration: '',
            play_time: '',

            show_lyrics: false,

            show_write: false,
            comment: '',
            write_loading: false,

            c_loading: true,
            comments: []
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.uploadurl + 'images/'
        },
        is_played() {
            return this.$root.$data.play_track.track_id == this.track.track_id
        },
        root_play_time() {
            return this.$root.$data.play_time
        }
    },
    watch: {
        root_play_time(val) {

            if (this.is_played) {
                this.value = val
            }

            const m = Math.floor(val % 3600 / 60).toString().padStart(2,'0'),
                s = Math.floor(val % 60).toString().padStart(2,'0');
            
            this.play_time = m + ':' + s;
        }
    },
    mounted() {
        let track = localStorage.getItem('track')
        if (track) {
            track = JSON.parse(track)
            if (track.track_id == this.$route.params.track_id) {
                
                this.tracks = JSON.parse( localStorage.getItem('tracks') )

                this.track = track

                this.is_track = true

                this.fetch_comments()
                this.get_duration()

                return
            }
        }

        this.loading = true

        let form = new FormData()
        form.append('track_id', this.$route.params.track_id)

        this.$http.post('/user/track', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.track = r.track
                this.is_track = true
                this.tracks = r.tracks
                
                localStorage.setItem('track', JSON.stringify(r.track))
                localStorage.setItem('tracks', JSON.stringify(r.tracks))

                this.loading = false

                this.fetch_comments()
                this.get_duration()
            }
        })
    },
    methods: {
        fetch_comments() {
            let form = new FormData()
            form.append('track_id', this.track.track_id)

            this.$http.post('/user/track/comments', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.comments = r.comments
                    this.c_loading = false
                }
            })
        },  
        get_duration() {
            
            this.slidermax = parseInt(this.track.duration)

            const m = Math.floor(this.track.duration % 3600 / 60).toString().padStart(2,'0'),
                s = Math.floor(this.track.duration % 60).toString().padStart(2,'0');
            
            this.duration = m + ':' + s;
        },
        compute_tooltip(e) {
            
            const m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
                s = Math.floor(e % 60).toString().padStart(2,'0');
            
            return m + ':' + s;
        },
        slider_seek() {
            console.log(this.$refs.slider.getIndex());
            if (!this.is_played) {
                this.play_first(); return
            }
            
            this.$root.$data.seek_track = { track_id: '' }
            setTimeout(() => {
                this.track['seek'] = this.$refs.slider.getIndex()
                this.$root.$data.seek_track = this.track
            }, 20);
        },
        submit() {
            if (this.comment == '') {
                this.comment_err(); return;
            }

            this.write_loading = true

            let form = new FormData()
            form.append('ep_id', this.track.ep_id)
            form.append('track_id', this.track.track_id)
            form.append('comment', this.comment)

            this.$http.post('/user/track/comment', form)
            .then(data => {
                let r = data.data

                if (r.success) {
                    this.success()

                    this.track.comments = parseInt(this.track.comments) + 1
                    localStorage.setItem('track', JSON.stringify(this.track))

                    setTimeout(() => {
                        this.$router.go()
                    }, 1000);
                }
            })
        }
    },
    notifications: {
        play_first: {
            type: 'info',
            title: '',
            message: 'Play the track first before seeking'
        },
        comment_err: {
            type: 'warn',
            title: '',
            message: 'Your comment cannot be empty'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Your comment has been submitted'
        }
    }
}
</script>

<style scoped>
    .track {
        
        height: 100%;
        overflow: auto;
        background-color: white;

        display: grid;
        grid-template-rows: auto max-content;
    }
    .l {
        margin-top: 20px;
    }
    .main {
        padding: 20px;
        box-sizing: border-box;
        overflow: auto;
    }
    
    .image {
        /* aspect-ratio: 4 / 3; */
        /* overflow: hidden; */

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 5px;
    }
    img {
        width: 100%;
    }


    .details {
        padding: 20px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        fill: var(--main);
    }
    .title {
        font-size: 18px;
        font-weight: 800;
    }
    .artist {
        margin-top: 5px;
        color: gray;
        font-size: 14px;
    }


    .player {
        margin-top: 20px;
    }
    .line {
        height: 2px;
        background-color: var(--main);
        position: relative;
    }
    .circle {
        position: absolute;
        top: -8px;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 1px solid var(--main);
        background-color: white;
    }
    .time {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        color: gray;
        margin-top: 20px;
    }
    .controls {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        gap: 30px;
        fill: var(--main);
    }
    .icon {
        cursor: pointer;
    }

    .section {
        margin-top: 20px;
    }
    .section_title {
        font-size: 18px;
        font-weight: 800;

        display: flex;
        align-items: center;
        justify-content: space-between;
        fill: var(--main);
        gap: 15px;
    }

    .write {
        margin-top: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 20px;
    }
    .write_title {
        font-weight: 600;
    }
    textarea {
        margin-top: 15px;
        margin-bottom: 15px;
        border: none;
        width: 100%;
        box-sizing: border-box;
        outline: none;
    }
    .btn {
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        padding: 12px;
    }

    

    .bottom {
        padding: 15px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: var(--main);
        color: white;
        background-color: #010051;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .bottom img {
        width: 20px;
    }
</style>