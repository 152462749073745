<template>
    <div class="head">
        
        <div class="user">
            <div class="dp">
                <img src="../assets/dp.svg"/>
            </div>
            {{$root.$data.firstname}}
        </div>
        <div class="logout" @click="logout()">Logout</div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('bitz')

export default {
    
    methods: {
        logout() {
            db.collection('auth').doc({ id: 1 }).delete()
            this.$router.push('/')
            this.$router.go()
        }
    }
}
</script>

<style scoped>
    
    .head {
        border-bottom: 1px solid #ddd;
        padding: 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .user {
        display: flex;
        align-items: center;
        font-size: 14px;
        gap: 5px;
        font-weight: 600;
    }
    .dp {
        width: 25px;
        height: 25px;
        overflow: hidden;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    .dp img {
        width: 25px;
    }


    .logout {
        font-size: 14px;
        font-weight: 500;
        /* padding: 5px 15px; */
        /* background-color: #edeef0; */
        color: var(--main);
        cursor: pointer;
        border-radius: 5px;
    }


</style>