<template>
    <div class="home">
        <!-- <headerlayout/> -->

        <div class="main">
            <div class="title">EPs</div>

            <loader class="l" v-if="loading"/>
            <ep v-for="ep in eps" :ep="ep" :key="ep.ep_id"/>
        </div>
        <div class="bottom">
            <img src="@/assets/logo.png"/>
            Bitz Music
        </div>
    </div>
</template>

<script>
// import headerlayout from '@/components/head.vue'

import loader from '@/components/util/loader.vue'

import ep from '@/components/home/ep.vue'

export default {
    components: {
        // headerlayout,
        loader,
        ep
    },
    data() {
        return {
            eps: [],
            loading: true
        }
    },
    mounted() {
        this.$http.post('/user/eps/get')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.eps = r.eps
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>
    .home {
        
        height: 100%;
        overflow: auto;
        background-color: white;

        display: grid;
        grid-template-rows: auto max-content;
    }
    

    .main {
        padding: 20px;
        box-sizing: border-box;
        overflow: auto;
    }
    .title {
        font-weight: 800;
        font-size: 18px;
    }
    .l {
        margin-top: 20px;
    }
    

    .bottom {
        padding: 15px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: var(--main);
        color: white;
        background-color: #010051;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .bottom img {
        width: 20px;
    }
</style>