<template>
    <div class="splash">
        <div>
            <div class="logo">
                <img src="../assets/logo.png" alt="">
            </div>
            <div v-if="show_install">
                <div class="btn" v-if="installing">
                    <install :size="'20px'"/>
                    Installing...
                </div>
                <div class="btn" v-else @click="install_pwa()">
                    <install :size="'20px'"/>
                    Install App
                </div>
            </div>
        </div>

        <div class="bottom" v-if="installing">
            <div class="title">Installing...</div>
            <div class="text">After installing, launch Bitz music app from your app list</div>
        </div>
        <div class="bottom" v-if="installed">
            <div v-if="isIOS">
                <div class="title">Install App</div>
                <div class="text">
                    To use Bitz music, click the browser share button, add it to your home screen, 
                    and launch from your app list.
                </div>
            </div>
            <div v-else>
                <div class="title">App Installed</div>
                <div class="text">Open Bitz music app from your app list</div>
            </div>
        </div>
    </div>
</template>

<script>
import install from '@/icons/install.vue';


export default {
    components: {
        install
    },
    computed: {
        show_install() {
            return this.$root.$data.pwa_install_prompt
        }
    },
    data() {
        return {
            installing: false,
            installed: false,
            isIOS: false,
        }
    },
    mounted() {
        
        this.isIOS = this.iOS()

        setTimeout(() => {
            if (this.$route.path.includes('admin')) {
                console.log('admin');
            } else {
                if (this.show_install) {
                    console.log('install');
                } else {
                    if (this.$root.$data.displayMode != 'standalone') {
                        this.installed = true
                    } else {
                        if (this.$root.$data.is_login) {
                            this.$router.push('/user')
                        } else {
                            this.$router.push('/signin')
                        }
                    }
                }
            }
        }, 2000);
    },
    methods: {
        
        async install_pwa() {
            if (this.$root.$data.pwa_install_prompt == null) {
                console.log('pwa not supported');
            }
            this.$root.$data.pwa_install_prompt.prompt()
            const { outcome } = await this.$root.$data.pwa_install_prompt.userChoice;
            
            
            if (outcome === 'accepted') {
                this.installing = true
                this.$root.$data.pwa_install_prompt = null;
            } else if (outcome === 'dismissed') {
                this.installing = false
                this.must_install()
            }
        },
        iOS() {
            return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        }
    },
    notifications: {
        must_install: {
            type: 'info',
            title: 'Install',
            message: 'To you Bitz music you must install'
        }
    }
}
</script>

<style scoped>
    .splash {
        height: 100vh;

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

    }

    .logo {
        text-align: center;
    }
    img {
        width: 100px;
    }

    .btn {
        background-color: var(--main);
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        margin: 0 auto;
        margin-top: 20px;

        display: flex;
        align-items: center;
        gap: 10px;
        fill: white;
    }


    .bottom {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .title {
        font-weight: 800;
    }
    .text {
        font-size: 14px;
        margin-top: 5px;
    }
</style>