<template>
    <div class="comment">
        <div class="time">{{date}}</div>
        <div class="text">
            {{comment.comment}}
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
    props: ['comment'],
    mounted() {
        this.date = moment(this.comment.created).fromNow()
    },
    data() {
        return {
            date: ''
        }
    }
}
</script>

<style scoped>
    .comment {
        margin-top: 20px;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 5px;
    }
    .time {
        font-size: 12px;
        color: gray;
    }
    .text {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        margin-top: 10px;
    }
</style>