<template>
    <div class="eps">
        <div class="head">
            <div class="title">EP's</div>
            <div class="btn" @click="show_add = true">Add</div>
        </div>

        <div class="main">
            <loader v-if="loading"/>
            <div v-else>
                <ep v-for="ep in eps" :ep="ep" :key="ep.ep_id"/>
            </div>
        </div>

        <add_ep v-if="show_add" @close="show_add = false"/>
    </div>
</template>

<script>

import loader from '@/components/util/loader.vue'
import ep from '@/components/admin/ep/ep.vue'

import add_ep from '@/components/admin/ep/add_ep.vue'

export default {
    components: {
        loader,
        ep,
        add_ep
    },
    data() {
        return {
            show_add: false,

            loading: true,
            eps: []
        }
    },
    mounted() {
        this.$http.post('/admin/eps/get')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.eps = r.eps
                this.loading = false
            }
        })
    }
}
</script>

<style scoped>
    .eps {
        padding: 20px;
    }

    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .title {
        font-size: 18px;
        font-weight: 800;
    }

    .btn {
        padding: 8px 20px;
        font-weight: 600;
        font-size: 14px;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }
    .main {
        padding-top: 20px;
    }
</style>