<template>
    <div id="app" :style="{'--main': c.main, '--trans': c.trans}">
        <div :class="[for_mobile ? 'route' : '']">
            <router-view v-if="ready"/>
        </div>
    </div>
</template>


<script>
import Localbase from 'localbase'
let db = new Localbase('bitz')

import {Howl} from 'howler';

export default {
    
    computed: {
        c() {
            return this.$root.$data.c
        },
        for_mobile() {
            return this.$route.meta.mobile
        },
        play_track() {
            return this.$root.$data.play_track
        },
        pause_track() {
            return this.$root.$data.pause_track
        },
        seek_track() {
            return this.$root.$data.seek_track
        }
    },
    watch: {
        play_track(val) {
            if (val.track_id == '') { return } 
            

            if (this.track.track_id == val.track_id) {
                
                this.play_next_chunk()
            } else {
                if (this.player.length > 0) {
                    this.player[0].stop()
                }
                
                clearInterval(this.interval)
                this.$root.$data.play_time = 0
                this.done = false
                this.current = 0
                this.player = []
                this.track = val
                this.current_id == ''

                let vm = this

                this.player.push(new Howl({
                    src: [val.file],
                    format: 'mp3',
                    onload: function() {
                        URL.revokeObjectURL(val.file)
                    },
                    onplay: function() {
                        vm.interval = setInterval(() => {
                            vm.$root.$data.play_time += 1
                        }, 1000);
                    },
                    onpause: function() {
                        clearInterval(vm.interval)
                    },
                    onend: function() {
                        clearInterval(vm.interval)
                        
                        vm.$root.$data.play_state = ''
                        
                        vm.current = 0
                        vm.track = {
                            'track_id': ''
                        }

                        vm.done = true
                    }
                }))
                
                this.play_next_chunk()
            }
        },
        pause_track(val) {
            if (val.track_id == '') { return } 
            this.pause()
        },
        seek_track(val) {
            if (val.track_id == '') { return } 
            
            this.$root.$data.play_time = val.seek
            this.player[0].seek(val.seek)
        }
    },
    created() {
        document.title = 'Bitz Music'
        
        window.addEventListener('beforeinstallprompt', (e) => {
            console.log('before install');

            e.preventDefault();
            
            console.log(this.$route.path);
            if (this.$route.path.includes('admin')) {
                console.log('admin');
            } else {
                this.$router.push('/').catch(() => {})
            }
            
            this.$root.$data.pwa_install_prompt = e;
        });
        window.addEventListener('DOMContentLoaded', () => {
            let displayMode = 'browser tab';
            if (window.matchMedia('(display-mode: standalone)').matches) {
                displayMode = 'standalone';
            }
            
            this.$root.$data.displayMode = displayMode
            if (displayMode != 'standalone') {
                console.log(this.$route.path);
                if (this.$route.path.includes('admin')) {
                    console.log('admin');
                } else {
                    this.$router.push('/').catch(() => {})
                }
            }
        });

    },
    mounted() {
        db.collection('auth').doc({ id: 1 }).get().then( auth => {
            if ( auth == undefined ) {
                console.log('ll');
            } else {
                this.$http.defaults.headers.common['Auth'] = auth.key

                this.$root.$data.is_login = true
                this.$root.$data.user_id = auth.user_id
                this.$root.$data.firstname = auth.firstname
                this.$root.$data.lastname = auth.lastname
                this.$root.$data.email = auth.email
                this.$root.$data.phone = auth.phone
                this.$root.$data.verified = auth.verified
            }

            this.ready = true
        })
    },
    data() {
        return {
            ready: false,
            track: {
                'track_id': ''
            },
            done: false,
            current: 0,
            current_id: '',
            player: [],

            interval: null
        }
    },
    methods: {
        play_next_chunk() {
            if (this.done) {
                this.$root.$data.play_state = ''
                
                this.current = 0
                // this.player = []
                this.track = {
                    'track_id': ''
                }
                this.current_id == ''

                return
            }
            this.$root.$data.play_state = 'playing'

            this.current_id = this.player[0].play()
            
        },
        pause() {
            this.$root.$data.play_state = ''
            this.player[0].pause(this.current_id)
        }
    }
}
</script>

<style>

body {
    padding: 0px;
    margin: 0px;
}

#app {
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    background-color: #edeef0;
    width: 100%;
    height: 100vh;

}

.route {
    width: 500px;
    margin: 0 auto;
    background-color: white;
}

a {
    color: inherit;
    text-decoration: none;
    width: fit-content;
}
a:active, a:focus {
    background-color: unset;
    color: unset;
    -webkit-tap-highlight-color: transparent;
}

textarea, input, select {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    resize: none;
}

.vue-slider-dot:hover .vue-slider-dot-tooltip .vue-slider-dot-tooltip-inner, 
.vue-slider-dot-tooltip-show .vue-slider-dot-tooltip-inner {
    transform: scale(0.9) !important;
}
.vue-slider-dot-tooltip-inner {
    border-color: var(--main) !important;
    background-color: var(--main) !important;
}
.vue-slider-dot-tooltip-inner {
    font-size: 12px !important;
}
.vue-slider-process {
    background-color: var(--main) !important;
}
.vue-slider-dot-handle {
    border-color: var(--main) !important;
}
.vue-slider-rail {
    background-color: #edeef0 !important;
}


@media screen and (max-width:600px) {
    .route {
        width: 100%;
    }
}

</style>
