<template>
    <div class="popup">
        <div class="dark" @click="$emit('close')"></div>
        <div class="box">
            <div class="title">Add Track</div>
            
            <div class="upload" @click="upload()">
                <img :src="image" alt="" v-if="is_image">
                <div class="upload_text" v-else>Click to upload track cover image</div>
            </div>
            <div class="input">
                <div class="label">Audio</div>
                <div class="audio" @click="upload_audio()">
                    <div v-if="is_audio">
                        Selected {{ audio_name }}
                    </div>
                    <div v-else>
                        Click to select track file
                    </div>
                </div>
            </div>

            <div class="input">
                <div class="label">Title</div>
                <input type="text" placeholder="Enter track title" v-model="title">
            </div>
            <div class="input">
                <div class="label">Artist</div>
                <input type="text" placeholder="Enter track Artist" v-model="artist">
            </div>
            <div class="input">
                <div class="label">Lyrics</div>
                <textarea placeholder="Enter track lyrics" v-model="lyrics"></textarea>
            </div>

            <btn_loader :height="'39px'" v-if="loading"/>
            <div class="btn" @click="add_track()" v-else>Add</div>
        </div>
        
        <input type="file" accept="image/*" class="hidden" ref="image" @change="handleupload($event)"/>
        <input type="file" accept="audio/*" class="hidden" ref="audio" @change="handleaudio($event)"/>
    </div>
</template>

<script>
import * as imageConversion from 'image-conversion';
import CryptoJS from "crypto-js"

import btn_loader from '@/components/util/btn_loader.vue';

export default {
    components: {
        btn_loader
    },
    props: ['ep'],
    data() {
        return {
            
            is_image: '',
            image: '',
            image_file: '',
            
            title: '',
            artist: '',
            lyrics: '',

            is_audio: false,
            audio_duration: '',
            audios: [],
            audio_name: '',

            temp_url: '',


            loading: false
        }
    },
    mounted() {
        
        // Encrypt
        var ciphertext = CryptoJS.AES.encrypt('my message', this.$root.$data.secret_key).toString();
        console.log(ciphertext);

        // Decrypt
        var bytes  = CryptoJS.AES.decrypt(ciphertext, this.$root.$data.secret_key);
        var originalText = bytes.toString(CryptoJS.enc.Utf8);

        console.log(originalText); // 'my message'
    },
    methods: {
        upload() {
            this.$refs['image'].click()
        },
        handleupload(event) {
            let mediaType = event.target.files[0].type
            if (mediaType.includes('image')) {
                const picture = event.target.files[0]
                
                this.compress(picture)
                const reader = new FileReader()
                reader.readAsDataURL(picture)
                reader.onload = event => {
                    this.image = event.target.result

                    this.$refs['image'].value = ''

                }
            }
        },
        compress(file) {
            let name = file.name
            
            imageConversion.compressAccurately(file, 70).then(res=>{
                this.is_image = true
                
                this.image_file = new File([res], name)
            })
        },
        upload_audio() {
            this.$refs['audio'].click()
        },


        async handleaudio(event) {
            const file = event.target.files[0];

            if (file) {

                const audioFile = event.target.files[0];
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();


                this.audio_name = audioFile.name
                

                const arrayBuffer = await audioFile.arrayBuffer();
                const buffer = await audioContext.decodeAudioData(arrayBuffer);
                this.audio_duration = buffer.duration


                const reader = new FileReader();
                reader.onload = (readerEvent) => {

                    var base64Input = readerEvent.target.result.split(',')[1]
                    console.log(base64Input.length);
                    
                    var ciphertext = CryptoJS.AES.encrypt(base64Input, this.$root.$data.secret_key).toString();
                    console.log(ciphertext.length);

                    
                    const base64blob = new Blob([ciphertext], { type: 'text/plain' });

                    const file = new File([base64blob], `audio.txt`);
                    console.log(file);
                    
                    this.audios.push(file)
                    this.is_audio = true

                };

                reader.readAsDataURL(file);
            }
        },

        async handleaudio_old(event) {
            
            this.is_audio = false
            this.audio_duration = ''
            this.audios = []
            this.audio_name = ''

            const file = event.target.files[0];
            
            if (file) {

                const audioFile = event.target.files[0];
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();

                this.audio_name = audioFile.name

                // Convert the file to an ArrayBuffer
                const arrayBuffer = await audioFile.arrayBuffer();

                // Decode the audio file into an AudioBuffer
                const buffer = await audioContext.decodeAudioData(arrayBuffer);

                this.audio_duration = buffer.duration

                // Define chunk size in seconds
                const chunkSize = 10;

                // Calculate the total number of chunks
                const totalChunks = Math.ceil(buffer.duration / chunkSize);

                // Split the audio into chunks
                for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                    const startTime = chunkIndex * chunkSize;
                    const endTime = Math.min((chunkIndex + 1) * chunkSize, buffer.duration);

                    // Create a new buffer for each chunk
                    const chunkBuffer = audioContext.createBuffer(1, Math.ceil((endTime - startTime) * buffer.sampleRate), buffer.sampleRate);

                    // Copy samples from the original buffer to the chunk buffer
                    const sourceData = buffer.getChannelData(0);
                    const targetData = chunkBuffer.getChannelData(0);
                    const startOffset = Math.floor(startTime * buffer.sampleRate);
                    const endOffset = Math.min(Math.ceil(endTime * buffer.sampleRate), buffer.length);

                    for (let i = startOffset, j = 0; i < endOffset; i++, j++) {
                        targetData[j] = sourceData[i];
                    }

                    // Process each chunk as needed (e.g., save to server, process, etc.)
                    // console.log(`Processing chunk ${chunkIndex + 1} of ${totalChunks}`);
                    // console.log(chunkBuffer);



                    
                    var channelData = [],
                    totalLength = 0,
                    channelLength = 0;

                    for (var i = 0; i < chunkBuffer.numberOfChannels; i++) {
                        channelData.push(chunkBuffer.getChannelData(i));
                        totalLength += channelData[i].length;
                        if (i == 0) channelLength = channelData[i].length;
                    }

                    // interleaved
                    const interleaved = new Float32Array(totalLength);

                    for (
                        let src = 0, dst = 0;
                        src < channelLength;
                        src++, dst += chunkBuffer.numberOfChannels
                    ) {
                        for (var j = 0; j < chunkBuffer.numberOfChannels; j++) {
                            interleaved[dst + j] = channelData[j][src];
                        }
                        //interleaved[dst] = left[src];
                        //interleaved[dst + 1] = right[src];
                    }




                    // get WAV file bytes and audio params of your audio source
                    const wavBytes = this.getWavBytes(interleaved.buffer, {
                        isFloat: true,       // floating point or 16-bit integer
                        numChannels: 2,
                        sampleRate: 48000,
                    })
                    const wav = new Blob([wavBytes], { type: 'audio/mp3' })

                    this.audios.push(wav)
                    // console.log(wav);

                }
                this.is_audio = true
            }
        },
        // Returns Uint8Array of WAV bytes
        getWavBytes(buffer, options) {
            const type = options.isFloat ? Float32Array : Uint16Array
            const numFrames = buffer.byteLength / type.BYTES_PER_ELEMENT

            const headerBytes = this.getWavHeader(Object.assign({}, options, { numFrames }))
            const wavBytes = new Uint8Array(headerBytes.length + buffer.byteLength);

            // prepend header, then add pcmBytes
            wavBytes.set(headerBytes, 0)
            wavBytes.set(new Uint8Array(buffer), headerBytes.length)

            return wavBytes
        },

        // adapted from https://gist.github.com/also/900023
        // returns Uint8Array of WAV header bytes
        getWavHeader(options) {
            const numFrames =      options.numFrames
            const numChannels =    options.numChannels || 2
            const sampleRate =     options.sampleRate || 44100
            const bytesPerSample = options.isFloat? 4 : 2
            const format =         options.isFloat? 3 : 1

            const blockAlign = numChannels * bytesPerSample
            const byteRate = sampleRate * blockAlign
            const dataSize = numFrames * blockAlign

            const buffer = new ArrayBuffer(44)
            const dv = new DataView(buffer)

            let p = 0

            function writeString(s) {
                for (let i = 0; i < s.length; i++) {
                dv.setUint8(p + i, s.charCodeAt(i))
                }
                p += s.length
            }

            function writeUint32(d) {
                dv.setUint32(p, d, true)
                p += 4
            }

            function writeUint16(d) {
                dv.setUint16(p, d, true)
                p += 2
            }

            writeString('RIFF')              // ChunkID
            writeUint32(dataSize + 36)       // ChunkSize
            writeString('WAVE')              // Format
            writeString('fmt ')              // Subchunk1ID
            writeUint32(16)                  // Subchunk1Size
            writeUint16(format)              // AudioFormat https://i.stack.imgur.com/BuSmb.png
            writeUint16(numChannels)         // NumChannels
            writeUint32(sampleRate)          // SampleRate
            writeUint32(byteRate)            // ByteRate
            writeUint16(blockAlign)          // BlockAlign
            writeUint16(bytesPerSample * 8)  // BitsPerSample
            writeString('data')              // Subchunk2ID
            writeUint32(dataSize)            // Subchunk2Size

            return new Uint8Array(buffer)
        },

        add_track() {
            if (!this.is_image || this.title == '' || this.artist == '' || !this.is_audio) {
                this.empty(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('ep_id', this.ep.ep_id)
            form.append('image', this.image_file)
            form.append('title', this.title)
            form.append('artist', this.artist)
            form.append('lyrics', this.lyrics)
            form.append('duration', this.audio_duration)

            let counts = []
            let count = 1


            for (let x of this.audios) {
                // form.append('audio' + count, new File([x], count + ".mp3"))
                form.append('audio' + count, x)
                counts.push('audio' + count)
                count++
            }
            form.append('audios', JSON.stringify(counts))

            for (var [key, value] of form.entries()) { 
                console.log(key, value);
            }

            this.$http.post('/admin/track/add', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    setTimeout(() => {
                        this.$router.go()
                    }, 1000);
                }
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: '',
            message: 'Make sure all field are filled included audio and image'
        },
        success: {
            type: 'success',
            title: '',
            message: 'Track added'
        }
    }
}
</script>

<style scoped>
    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;

        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;

        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .box {
        position: relative;
        background-color: white;
        border-radius: 5px;
        width: 300px;
        padding: 20px;
        max-height: 80vh;
        overflow: auto;
    }

    .title {
        font-weight: 800;
        margin-bottom: 20px;
    }


    .upload {
        background-color: #edeef0;
        aspect-ratio: 1/1;
        border-radius: 5px;
        overflow: hidden;

        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        cursor: pointer;
    }
    .upload_text {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    }
    .upload img {
        width: 100%;
    }

    .input {
        margin-bottom: 20px;
    }
    .label {
        font-size: 14px;
        font-weight: 500;
    }
    .audio {
        padding: 15px 12px;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
        background-color: #edeef0;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
    }
    input, textarea {
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    textarea {
        height: 150px;
    }
    .btn {
        padding: 10px;
        text-align: center;
        background-color: var(--main);
        color: white;
        font-weight: 600;
        font-size: 600;
        border-radius: 5px;
        cursor: pointer;
    }

    
    .hidden {
        display: none;
    }
</style>