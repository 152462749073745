import Vue from 'vue'
import VueRouter from 'vue-router'

import splash from '../views/splash.vue'
import signin from '../views/signin.vue'
import signup from '../views/signup.vue'

import user from '../views/user/user.vue'
import registered from '../views/user/registered.vue'
import home from '../views/user/home.vue'
import track from '../views/user/track.vue'


import adminlogin from '../views/admin/login.vue'

import admin from '../views/admin/admin.vue'
import dashboard from '../views/admin/dashboard.vue'
import users from '../views/admin/users.vue'
import eps from '../views/admin/eps.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'splash',
        component: splash,
        meta: {
            mobile: true
        }
    },
    {
        path: '/signin',
        name: 'signin',
        component: signin,
        meta: {
            mobile: true
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: signup,
        meta: {
            mobile: true
        }
    },
    {
        path: '/user',
        name: 'user',
        component: user,
        children: [
            {
                path: '/registered',
                name: 'registered',
                component: registered,
                meta: {
                    mobile: true
                }
            },
            {
                path: '/home',
                name: 'home',
                component: home,
                meta: {
                    mobile: true
                }
            },
            {
                path: '/track/:track_id',
                name: 'track',
                component: track,
                meta: {
                    mobile: true
                }
            },

        ]
    },
    {
        path: '/admin/login',
        name: 'login',
        component: adminlogin
    },
    {
        path: '/admin',
        name: 'admin',
        component: admin,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: dashboard
            },
            {
                path: 'users',
                name: 'users',
                component: users
            },
            {
                path: 'eps',
                name: 'eps',
                component: eps
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
