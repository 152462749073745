<template>
    <div class="dashboard">
        Dashboard
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .dashboard {
        padding: 20px;
    }
</style>