<template>
    <div class="ep">
        <div class="image">
            <img :src="imgurl + ep.image"/>
        </div>
        <div class="main">
            <div class="head">
                <div>
                    <div class="ep_title">{{ep.title}}</div>
                    <div class="small">
                        <div>{{ep.tracks}} Tracks</div>
                        <div>{{ep.votes}} Votes</div>
                    </div>
                </div>
                <div class="btn" @click="soon()">Vote</div>
            </div>
            <div class="menu">
                <div class="item a">Tracks</div>
                <div class="item" @click="soon()">Votes</div>
            </div>
            <div class="section">

                <loader class="l" v-if="loading"/>
                <trackVue v-for="track in tracks" :key="track.track_id" 
                    :track="track" :tracks="tracks"/>
            </div>
        </div>
    </div>
</template>

<script>
// import play from '../../icons/play.vue'

import trackVue from './track.vue'
import loader from '../util/loader.vue'

export default {
    components: {
        // play, 
        trackVue,
        loader
    },
    props: ['ep'],
    data() {
        return {
            loading: true,
            tracks: []
        }
    },
    computed: {
        imgurl() {
            return this.$root.$data.uploadurl + 'images/'
        }
    },
    mounted() {
        let form = new FormData()
        form.append('ep_id', this.ep.ep_id)

        this.$http.post('/user/tracks/get', form)
        .then(data => {
            let r = data.data
            if (r.success) {
                this.tracks = r.tracks
                this.loading = false
            }
        })
    },
    methods: {
        soon() {
            this.coming_soon()
        }
    },
    notifications: {
        coming_soon: {
            type: 'info',
            title: 'Coming Soon',
            message: 'This feature is coming soon'
        }
    }
}
</script>

<style scoped>

    .ep {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 50px;
        border-radius: 5px;
        overflow: hidden;
    }
    .image {
        /* aspect-ratio: 4 / 3; */
        /* overflow: hidden; */

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 5px;
    }
    img {
        width: 100%;
    }


    .main {
        /* border: 1px solid #ddd; */
        border-top: none;
        overflow: hidden;
        border-radius: 5px;
    }
    .head {
        padding: 20px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .ep_title {
        font-size: 18px;
        font-weight: 800;
    }
    .small {
        display: flex;
        gap: 10px;
        margin-top: 5px;
        font-size: 12px;
        color: gray;
    }
    .btn {
        padding: 8px 20px;
        font-size: 14px;
        font-weight: 500;
        background-color: var(--main);
        color: white;
        border-radius: 5px;
        cursor: pointer;
    }

    .menu {
        display: flex;
        border-bottom: 1px solid #ddd;
    }
    .item {
        font-size: 14px;
        font-weight: 500;
        padding: 10px 20px;
        border: 2px solid transparent;
        color: gray;
        cursor: pointer;
    }
    .a {
        color: var(--main);
        border-bottom-color: var(--main);
    }


    .section {
        /* padding: 20px; */
        padding-top: 0px;
    }
    .l {
        margin-top: 20px;
    }
    .section_head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        position: relative;
        /* padding-bottom: 10px; */
    }
    .title {
        font-weight: 500;
        font-size: 20px;
    }
    .play {
        position: absolute;
        right: 0px;
        fill: var(--main);
    }
</style>