<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M292.309-60.002q-30.308 0-51.308-21-21-20.999-21-51.307v-695.382q0-30.308 
            21-51.308t51.308-21h269.23V-840h-269.23q-4.616 0-8.463 3.846-3.846 3.847-3.846 
            8.463v37.692h281.539v59.998H280v500.002h400v-80h59.999v177.69q0 30.308-21 51.308t-51.308 
            21H292.309ZM280-170v37.692q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h375.382q4.616 0 
            8.463-3.846 3.846-3.847 3.846-8.463v-37.692H280Zm440-244.231L533.847-600.385 576-642.537l114.001 
            114v-301.462h59.998v301.462l114.001-114 42.153 42.152L720-414.232ZM280-789.999V-840v50.001Zm0 
            619.998V-120v-50.001Z"/>
        </svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>