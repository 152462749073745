<template>
    <div class="users">
        <div class="title">Users</div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .users {
        padding: 20px;
    }
    .title {
        font-size: 18px;
        font-weight: 800;
    }
</style>