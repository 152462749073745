<template>
    <div class="track">
        <div class="flex">
            <div class="icon">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
            <div class="image">
                <img :src="imgurl + track.image" alt="">
            </div>
            <div class="title">{{ track.title }}</div>
            <div class="comments">{{ track.comments }} Comments</div>
        </div>
    </div>
</template>

<script>
import arrow from '@/icons/arrow.vue'

export default {
    components: {
        arrow
    },
    props: ['track'],
    computed: {
        imgurl() {
            return this.$root.$data.uploadurl + 'images/'
        }
    }
}
</script>

<style scoped>
    
    .track {
        border-radius: 5px;
        background-color: #edeef0;
        border-radius: 5px;

        margin-top: 20px;
    }
    .flex {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        cursor: pointer;
    }
    .image {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        overflow: auto;
        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .image img {
        width: 50px;
    }
    .title {
        font-weight: 600;
    }
    .comments {
        background-color: var(--trans);
        color: var(--main);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: 600;
    }
    .icon {
        transition: .3s;
    }
    .rot {
        transform: rotate(90deg);
    }
</style>